/* eslint-disable consistent-return */
/* eslint-disable no-console */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { showNotification } from 'config/toast';
import { getValidErrorMessage } from 'utils';
import { retry } from 'utils/retry';
import { setAuthCookie } from 'utils/api';
import { User } from '../../types/auth';
import { authApi } from '../../api/auth';
import { localStorageService } from '../../libs/localStorage/local.storage.service';
import { setUser, removeUser } from './index';
import { axiosApiInstance } from '../../api/axios';
import { changeLanguage } from 'config/i18n';
import { DEFAULT_LOCALE } from '../../constants/languages';

export const updateUser = createAsyncThunk('auth/updateUser', async (_, { dispatch }) => {
  try {
    const user = await authApi.getUser();

    dispatch(setUser(user));

    if (user) {
      document.cookie = `lang=${user.language ?? DEFAULT_LOCALE}; domain=${window.location.hostname}; path=/`;
    }
    return user;
  } catch (error) {
    console.error(error);
    showNotification(getValidErrorMessage(error), 'error');
    return error;
  }
});

export const logout = createAsyncThunk('auth/logout', async (_, { dispatch }) => {
  try {
    await retry(authApi.logout);

    dispatch(removeUser());
    setAuthCookie('', '');
  } catch (error) {
    console.error(error);
    return error;
  } finally {
    // Remove local data
    dispatch(removeUser());
    setAuthCookie('', '');
  }
});

export const refreshToken = createAsyncThunk('auth/refresh', async (ignoreErrors: boolean, { dispatch }) => {
  try {
    const { data } = await authApi.refreshToken({
      ...localStorageService.getTokens(),
      user: localStorageService.getUser() as User,
    });
    axiosApiInstance.defaults.headers.common.Authorization = `Bearer ${data.accessToken}`;
    dispatch(setUser(data.user));

    setAuthCookie(data.accessToken, data.user.role);
    await changeLanguage(data.user.language);

    return data;
  } catch (error) {
    // logout
    setAuthCookie('', '');
    dispatch(removeUser());
    if (!ignoreErrors) {
      showNotification(getValidErrorMessage(error), 'error');
      console.error(error);
    }
  }
});
