export const mediaMax = (width: number | string, unit = 'px'): string => `@media (max-width: ${width}${unit})`;
export const mediaMaxHeight = (height: number | string, unit = 'px'): string => `@media (max-height: ${height}${unit})`;

export const mediaMaxHeightOrientation = (height: number | string, orientation: string, unit = 'px'): string =>
  `@media (max-height: ${height}${unit}) and (orientation: ${orientation})`;

export const mediaMin = (width: number | string, unit = 'px'): string => `@media (min-width: ${width}${unit})`;

export const mediaMinMax = (widthMin: number | string, widthMax: number | string, unit = 'px'): string =>
  `@media (min-width: ${widthMin}${unit}) and (max-width:${widthMax}${unit})`;

export const breakpoints = {
  mobileXS: 321,
  mobileS: 350,
  mobileSM: 360,
  mobileMS: 375,
  mobileM: 384,
  mobileMLand: 667,
  mobileL: 428,
  mobileLLand: 736,
  tabletXS: 530,
  tabletM: 768,
  muiMd: 800,
  tabletL: 1024,
  laptopS: 1201,
  laptomSM: 1220,
  laptopM: 1440,
  laptopL: 1600,
  desktop: 1920,
  builder: {
    lg: 1262,
    md: 894,
    sm: 457,
  },
  landscape: 440,
  landscapeS: 350,
};

export const COLORS = {
  WHITE: '#FFFFFF',

  PRIMARY_TEXT: '#1E1D20',
  SECONDARY_TEXT: '#424A5C',
  INACTIVE_TEXT: '#A1A4AC',
  BLUE500: '#5D82D5',
  BLUE: '#5D82D5',
  BLUE_HOVERED: '#3C6BC4',
  NAVY: '#2E4370',
  INPUT_PLACEHOLDER: '#E0E2E4',

  // red
  RED500: '#EF5D5D',

  YELLOW500: '#FE8C25',
  YELLOW_HOVERED: '#FC7A05',

  TRANSPARENT: 'transparent',
  MAIN_BACKGROUND: '#F8F8F8',
  MAIN_BLACK: '#000000',
  SUCCESS_COLOR: '#3CBE91',
  PAUSED_COLOR: '#A0A4AD',

  BORDER: '#D9DCE1',

  ICON_GREY: '#D9DCE1',
  DASH_GREY: 'rgba(112, 112, 112, 0.15)',
  LIGHTGREY: '#F8F8F8',
  BORDER_LIGHTGREY: '#e6e6e6',
  BORDER_LIGHTGREY_PRICES: '#edeef0',
  BORDER_DASHED_LIGHTGREY: '#e8e8e8',
  FORM_ELEMENT_BORDER: '#D9DCE1',
  FORM_ELEMENT_FOCUS: 'rgba(63, 126, 247, 0.36)',
  ERROR: '#EF5D5D',

  // buttons
  BUTTON_PRIMARY: '#FE8C25',
  PRIMARY_HOVER: '#FC7A05',
  BUTTON_SECONDARY: '#5482DB',
  SECONDARY_HOVER: '#3C6BC4',
  INACTIVE_BUTTON: '#A0A4AD',
  INACTIVE_BUTTON_HOVER: '#8C8C8C',

  // breadcrumbs mobile

  BREADCRUMB_MOBILE_INACTIVE: '#B0B3B9',

  // customer reviews
  REVIEV_AVATAR_COLOR1: '#F6B18A',
  REVIEV_AVATAR_COLOR2: '#A5A9FB',
  REVIEV_AVATAR_COLOR3: '#E89296',

  // blog content
  BLOG_BULLET_COLOR: '#a7abb5',
  BLOG_BORDERED_LIST_BORDER_COLOR: '#f2f5fc',

  // FOOTER
  FOOTER_BORDER_COLOR: '#E9E9E9',
};
